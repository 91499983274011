import React, { useState, useEffect, lazy, Suspense } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Button from '../Button';
import { dark } from '../../styles/Themes';
import Loading from '../Loading';

const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.text};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 75%;
margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`
const Box = styled.div`
width: 50%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 40em){
  min-height: 50vh;
}
`
 
const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
margin: 0 auto;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.body};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  align-self: flex-start;
  width: 80%;
margin: 1rem auto;
font-weight:400;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontsm};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};

}

`
const ButtonContainer = styled.div`
 width: 80%;
 margin: 1rem auto;
 display: flex;
  align-self: flex-start;

  @media (max-width: 64em){
width: 100%;

button{
  margin: 0 auto;
}
}

`

const About = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const text = [
    {
      title: 'How Do We Benefit Bar Owners?',
      subText: 'Easily Manage Your Bar Data and Functions',
      subTextLight: 'Once a bar is registered, owners will get access to the Owner Dashboard. On the dashboard, owners can do the following: manage bartenders, edit their menu, monitor sales, gain insights, and much more. Best of all, registering a bar is completly free and can run seamlessly regardless of exisiting POS system!',
    },
    {
      title: 'How Do We Benefit Bartenders?',
      subText: 'Serve Customers Faster and Stress-Free',
      subTextLight: 'Once a bar is registered, orders that are placed can be viewed on the BarMonkey Bartender Portal. Ideally, each bartender should have access to their own Bartender Portal via a tablet. Orders will appear on the portal as they are placed and bartenders are assigned optimally using our proprietary algorithm.',
    },
    {
      title: 'How Do we Benefit Customers?',
      subText: 'Make the Most of Your Time',
      subTextLight: 'Customers can place orders without needing to get the attention of bartenders, especially when its too crowded to. Since customers will use our mobile app, customers can enjoy the following benefits: no more waiting for receipt to tip, view popular orders, split orders with friends seamlessly, meet new people at the bar, and much more!',
    },
    // Add more slide text as needed
  ];

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  return (
    <Section id="about">
      {/* ... */}
        <Box>
          <Suspense fallback={<Loading />}>
            <Carousel onSlideChange={handleSlideChange} />
          </Suspense>
        </Box>
        <Box>
          <Title>{text[currentSlide].title}</Title>
          <SubText>{text[currentSlide].subText}</SubText>
          <SubTextLight>{text[currentSlide].subTextLight}</SubTextLight>
          {/* ... */}
        </Box>
      {/* ... */}
    </Section>
  );
};


export default About