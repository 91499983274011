import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';


const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="WILL A BAR APPEAR IN-APP EVEN IF IT IS NOT REGISTERED?" >
  We have currently initialized the app with all bars in the NYC area. When a bar is not registered, customers cannot place orders but will be able to view the menu and view others using the BarMonkey app at/near the bar.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT IS THE PROCESS/COST FOR REGISTERING A NEW BAR?" >
  Registering a bar with BarMonkey is very straightforward and completely free. As an owner, simply click the "register bar" button on our site and fill out the survey. Once our team verifies the information, we will reach out to the owner and aid with the initial set up. 

  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="I HAVE AN IDEA FOR A NEW FEATURE, WHERE CAN I SUBMIT IT?" >
  We appreciate feedback and feature ideas! Please email us at info@barmonkey.io.
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="IS THERE AN AGE RESTRICTION? HOW DOES BARMONKEY HANDLE UNDERAGE DRINKING?" >
The app can be used by those that are 18 and older. We do not support underage drinking in any way. As of now, the in-app buying feature will only operate at 21 and older bars. This way, it will be impossible for an underage user to pick up a drink. Going forward, we are working on implementing ID verification so users can buy drinks in any bar only if they are of age.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="HOW DOES BARMONKEY HANDLE REFUNDS/CANCELLATIONS?
" >
When users place orders in-app, they are warned that their order cannot be editted or cancelled. If order is placed and needs to be editted or cancelled, the customer will have to directly ask the bar staff.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="WHAT DATA DOES BARMONKEY COLLECT? WHAT DOES BARMONKEY DO WITH THAT DATA?" >
Barmonkey does not store payment related information such as credit card details and values the privacy of all users alike. However, BarMonkey will collect sale related data to help customers find popular items and help owners optimize their menu.
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq