import React, { useRef } from 'react'
import styled, { keyframes } from 'styled-components'

import img1 from '../../assets/Nfts/ai_blog.jpeg';
import img2 from '../../assets/Nfts/pop_blog.jpeg';
import img3 from '../../assets/Nfts/bighead-2.svg';
import img4 from '../../assets/Nfts/bighead-3.svg';
import img5 from '../../assets/Nfts/bighead-4.svg';
import img6 from '../../assets/Nfts/automation_blog.jpeg';
import img7 from '../../assets/Nfts/wine_blog.jpeg';
import img8 from '../../assets/Nfts/bighead-7.svg';
import img9 from '../../assets/Nfts/bighead-8.svg';
import img10 from '../../assets/Nfts/bighead-9.svg';

const Section = styled.section`
min-height: 100vh;
width: 100vw;
background-color: ${props => props.theme.text};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

&>*:first-child{
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:last-child{
  animation-duration: 15s;
  @media (max-width: 30em){
    animation-duration: 10s;

  }
}
`
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`

const Row = styled.div`
/* background-color: lightblue; */
white-space: nowrap;
box-sizing:content-box;
margin: 2rem 0;
display: flex;

animation: ${move}  linear infinite ${props => props.direction};


`
const ImgContainer = styled.div`
width: 15rem;
height: 20rem;
margin: 0 1rem;
background-color:${props => props.theme.body};

border-radius: 20px;
cursor: pointer;

@media (max-width: 48em){
  width: 12rem;
  }
  @media (max-width: 30em){
  width: 10rem;
  }

img {
  width: 100%;
  height: 100%;
  object-fit: cover;  // This will ensure the image covers the container without distortion
  object-position: center;  // This will position the image in the center of the container
}
  
`

// ... (Other imports)

// New component name: BlogItem
const BlogItem = ({ img, title, passRef, link }) => {

  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  }

  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  }

  let handleLink = (e) => {
    if (link) {
      window.open(link, '_blank');
    }
  }

  return (
    <ImgContainer onClick={handleLink} onMouseOver={e => pause(e)} onMouseOut={e => play(e)}>
      <img width={500} height={400} src={img} alt={title} />
      <Details>
        <h1>{title}</h1>
      </Details>
    </ImgContainer>
  )
}


const Details = styled.div`
  text-align: center;
  padding: 0.8rem 1rem;
  background-color: ${props => props.theme.text};
  border: 2px solid ${props => `rgba(${props.theme.bodyRgba},0.5)`};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  h1 {
    font-size: ${props => props.theme.fontmd};
    color: ${props => props.theme.body};
    font-weight: 600;
    @media (max-width: 30em) {
      font-size: ${props => props.theme.fontsm};
    }
  }
`;

// In the Showcase component
const Showcase = () => {
  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return (
    <Section id="showcase">
      <Row direction="none" ref={Row1Ref}>
        <BlogItem img={img1} title="The Ideal Bar" passRef={Row1Ref} link="https://hurricane-jumper-0ef.notion.site/We-Asked-AI-to-Create-the-Ideal-Bar-22e376b9d2d24523822d0980095fdcdf?pvs=4" />
        <BlogItem img={img2} title="Bars + Pop Culture" passRef={Row1Ref} link="https://hurricane-jumper-0ef.notion.site/The-Role-of-Bars-In-Pop-Culture-ccb95b1989f84e55ae786b3444e7d817?pvs=4"/>
      </Row>
      <Row direction="reverse" ref={Row2Ref}>
        <BlogItem img={img6} title="Automating Bars" passRef={Row2Ref} link="https://hurricane-jumper-0ef.notion.site/Technology-at-the-Tavern-First-Steps-of-Automation-in-Bars-e67ae9b194b84a5781beb4d3e12b1dcf?pvs=4"/>
        <BlogItem img={img7} title="Wine and Tapas" passRef={Row2Ref} link="https://hurricane-jumper-0ef.notion.site/Wine-and-Tapas-A-Match-Made-in-Heaven-d8fea24e11e84f3da1c1dc37487cc093?pvs=4"/>
      </Row>
    </Section>
  )
}

export default Showcase;